// image sprite
.image_box {
  .image_sprite {
    background: url('../images/image.png');
  }

  .IC_navlue_s {
    background-position: 0 -106px;
  }

  /* empty img */
  .IC_empty_img {
    background-position: 0 -215px;
  }

  /* blue */
  .IC_menu_b {
    background-position: 0 -125px;
  }
  .IC_snow_b {
    background-position: calc(-24px * 1) -125px;
  }
  .IC_rain_b {
    background-position: calc(-24px * 2) -125px;
  }
  .IC_fog_b {
    background-position: calc(-24px * 3) -125px;
  }
  .IC_light_b {
    background-position: calc(-24px * 4) -125px;
  }
  .IC_backlight_b {
    background-position: calc(-24px * 5) -125px;
  }
  .IC_night_b {
    background-position: calc(-24px * 6) -125px;
  }
  .IC_smoke_b {
    background-position: calc(-24px * 7) -125px;
  }
  .IC_tag_b {
    background-position: calc(-24px * 8) -125px;
  }
  .IC_table_b {
    background-position: calc(-24px * 9) -125px;
  }
  .IC_link_b {
    background-position: calc(-24px * 10) -125px;
  }

  /* white */
  .IC_table_w {
    background-position: 0 -149px;
  }
  .IC_thumb_w {
    background-position: calc(-24px * 1) -149px;
  }
  .IC_max_w {
    background-position: calc(-24px * 2) -149px;
  }
  .IC_upload_w {
    background-position: calc(-24px * 3) -149px;
  }
  .IC_download_w {
    background-position: calc(-24px * 4) -149px;
  }
  .IC_add_w {
    background-position: calc(-24px * 5) -149px;
  }
  .IC_remove_w {
    background-position: calc(-24px * 6) -149px;
  }
  .IC_complete_w {
    background-position: calc(-24px * 7) -149px;
  }
  .IC_delete_w {
    background-position: calc(-24px * 8) -149px;
  }
  .IC_tag_w {
    background-position: calc(-24px * 9) -149px;
  }
  .IC_data_w {
    background-position: calc(-24px * 10) -149px;
  }
  .IC_stats_w {
    background-position: calc(-24px * 11) -149px;
  }
  .IC_reset_w {
    background-position: calc(-24px * 12) -149px;
  }
  .IC_up_w {
    background-position: calc(-24px * 13) -149px;
  }

  /* black */
  .IC_up_k {
    background-position: 0 -173px;
  }
  .IC_down_k {
    background-position: calc(-24px * 1) -173px;
  }
  .IC_first_k {
    background-position: calc(-24px * 2) -173px;
  }
  .IC_prev_k {
    background-position: calc(-24px * 3) -173px;
  }
  .IC_next_k {
    background-position: calc(-24px * 4) -173px;
  }
  .IC_last_k {
    background-position: calc(-24px * 5) -173px;
  }
  .IC_logout_k {
    background-position: calc(-24px * 6) -173px;
  }
  .IC_min_k {
    background-position: calc(-24px * 7) -173px;
  }
  .IC_close_k {
    background-position: calc(-24px * 8) -173px;
  }
  .IC_link_k {
    background-position: calc(-24px * 9) -173px;
  }
  .IC_profile_k {
    background-position: calc(-24px * 10) -173px;
  }
  .IC_reload_k {
    background-position: calc(-24px * 11) -173px;
  }

  /* small */
  .IC_home_s {
    background-position: 0 -197px;
  }
  .IC_data_s {
    background-position: calc(-18px * 1) -197px;
  }
  .IC_chart_s {
    background-position: calc(-18px * 2) -197px;
  }
  .IC_camera_s {
    background-position: calc(-18px * 3) -197px;
  }
  .IC_radar_s {
    background-position: calc(-18px * 4) -197px;
  }
  .IC_stats_s {
    background-position: calc(-18px * 5) -197px;
  }
  .IC_close_s {
    background-position: calc(-18px * 6) -197px;
  }
  .IC_filter_s {
    background-position: calc(-18px * 7) -197px;
  }
  .IC_date_s {
    background-position: calc(-18px * 8) -197px;
  }
  // IC_date_s와 같음
  .IC_datetime_s {
    background-position: calc(-18px * 8) -197px;
  }
  .IC_time_s {
    background-position: calc(-18px * 9) -197px;
  }
  .IC_preview_s {
    background-position: calc(-18px * 10) -197px;
  }

  .IC_snow_s {
    background-position: calc(-18px * 11) -197px;
  }
  .IC_rain_s {
    background-position: calc(-18px * 12) -197px;
  }
  .IC_fog_s {
    background-position: calc(-18px * 13) -197px;
  }
  .IC_light_s {
    background-position: calc(-18px * 14) -197px;
  }
  .IC_backlight_s {
    background-position: calc(-18px * 15) -197px;
  }
  .IC_night_s {
    background-position: calc(-18px * 16) -197px;
  }
  .IC_smoke_s {
    background-position: calc(-18px * 17) -197px;
  }
}
