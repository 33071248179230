$gray100: #f9f9f9;
$gray200: #f2f2f2;
$gray300: #e1e1e1;
$gray400: #d0d0d0;
$gray500: #c4c4c4;
$gray600: #999999;
$gray700: #808080;
$gray800: #676767;
$gray900: #333333;
$primary: #2b63c1;
$error: #e20000;
$warning: #ffa500;
$gradient0: #1a237e;
$gradient1: #42b3d5;
$gradient2: #dcedc8;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
}

p {
  padding: 0;
  margin: 0;
}

textarea {
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif !important;
  color: $gray900;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-text-fill-color: $gray800 !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar-track {
  width: 8px;
  background-color: transparent;
  margin-top: 0px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $gray200;
}

.transparent_scroll::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background-color: $gray500;
}

// tw reset
::before,
::after {
  box-sizing: unset !important;
}

img {
  max-width: none !important;
}

// font-family
.spoqa {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

// keyframes, animation
.spinner {
  -webkit-animation: sk-rotateplane 2.5s infinite ease-in-out;
  animation: sk-rotateplane 2.5s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

// scroll
nav {
  scrollbar-color: $gray800 transparent;

  &::-webkit-scrollbar {
    background-color: $gray900;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray800;
  }
}

.scroll_head {
  &::-webkit-scrollbar-track {
    margin-top: 33px;
  }
}

// mui text field
.MuiTextField-root {
  > div {
    height: 30px;
    border: 1px solid $gray400;
    border-radius: 2px !important;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 12px !important;
    color: $gray800 !important;

    > input {
      padding: 8.5px 12px;
    }

    > fieldset {
      border: none !important;
    }
  }
}

.save_modal {
  .MuiTextField-root {
    > div {
      width: 234px;
    }
  }
}

// table
table {
  width: 100%;

  tr {
    height: 34px;
    font-size: 12px;
    color: $gray800;

    td {
      padding: 4px 16px;
      border-bottom: 1px solid $gray300;
    }
  }

  thead {
    tr {
      height: 33px;
      background-color: $gray200;
      font-weight: bold;
    }
  }
}

// mui table
table {
  th.MuiTableCell-head,
  td.MuiTableCell-body {
    height: 34px;
    padding: 4px 16px;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    color: $gray800;
  }

  th.MuiTableCell-head {
    background-color: $gray200;
    font-weight: bold;
  }

  td.MuiTableCell-body {
    font-weight: inherit !important;
  }

  .tablecell_index,
  .tablecell_checkbox {
    padding: 4px 8px !important;
    text-align: center;
  }
}

.ani-none .MuiLinearProgress-bar {
  transition: none !important;
  -webkit-animation: none !important;
          animation: none !important;
}

.MuiTablePagination-root {
  padding: 0 !important;
  border-bottom: none !important;
}

.MuiTablePagination-displayedRows {
  display: none !important;
}

.MuiTablePagination-toolbar {
  min-height: 34px !important;
  padding-left: 8px !important;
}

// preview rotate
.preview_close {
  &::before {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}


.for-a11y {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.preview_prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 20px;
}

.preview_next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 20px;
}

// react-zoom-pan-pinch
.react-transform-wrapper {
  width: 100vw !important;
  height: 100vh !important;
  padding-top: 0 !important;
  overflow: hidden !important;

  .react-transform-component {
    overflow: hidden !important;
  }
}

// input range
input[type='range'] {
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
}

::-moz-range-thumb {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 6px;
  background-color: white;
}

// media query
@media (max-width: 960px) {
  section {
    width: 960px;
  }
}
